import { Controller } from 'stimulus';
import { STATES_HTML, CANADIAN_PROVINCES_HTML } from '../src/utils';
import { districtsForState, districtOptionGroup } from '../src/utils/districts';

export default class extends Controller {
  static targets = ['disclaimer', 'country', 'region', 'district'];

  static outlets = ['subsidiary'];

  updateCountry(event) {
    this.updateRegionInput(event.target.value);
    this.updateDistrictInput(event.target.value);
    this.updateDisclaimer(event.target.value);
  }

  updateState() {
    if (this.countryTarget.value === 'United States of America') {
      this.updateDistrictOptions(this.regionTargetInput().value);
    }
  }

  async updateDistrictOptions(state) {
    const districts = districtsForState(state);
    if (districts.length) {
      const districtTargetInput = this.districtTargetInput();
      let selected = null;
      if (this.hasSubsidiaryOutlet) {
        selected = await this.subsidiaryOutlet.findSubsidiary();
      }
      this.districtTargetInput().outerHTML = `<select class='form-select' data-subsidiary-target=${districtTargetInput?.dataset?.subsidiaryTarget} name=${districtTargetInput.name} options=${districtOptionGroup(districts, selected.district_or_county)} data-copy-from-target="input">`;

      this.districtTarget.classList.remove('hidden');
    } else {
      this.districtTarget.classList.add('hidden');
    }
  }

  updateRegionInput(countryName) {
    const regionTargetInput = this.regionTargetInput();
    if (countryName === 'United States of America') {
      this.regionTarget.innerHTML = `<label for=${regionTargetInput.name}>State</label>
      <select data-governing='true' class='form-select' data-subsidiary-target=${regionTargetInput?.dataset?.subsidiaryTarget} data-action="courts-and-jurisdiction#updateState" id=${regionTargetInput.id} name=${regionTargetInput.name} data-copy-from-target="input">${STATES_HTML}</select>`;
    } else if (countryName === 'Canada') {
      this.regionTarget.innerHTML = `<label for=${regionTargetInput.name}>Province</label>
      <select data-governing='true' class='form-select'  data-subsidiary-target=${regionTargetInput?.dataset?.subsidiaryTarget} id=${regionTargetInput.id} name=${regionTargetInput.name} data-copy-from-target="input">${CANADIAN_PROVINCES_HTML}</select>`;
    } else {
      this.regionTarget.innerHTML = `<label for=${regionTargetInput.name}>Administrative region <span class='text-subdued'>(optional)</span></label>
      <input id=${regionTargetInput.id} name=${regionTargetInput.name} type='text' data-subsidiary-target=${regionTargetInput?.dataset?.subsidiaryTarget} placeholder='Administrative region' data-copy-from-target="input">`;
    }
  }

  updateDistrictInput(countryName) {
    if (this.hasDistrictTarget) {
      if (countryName === 'United States of America') {
        this.updateDistrictOptions(this.regionTargetInput().value);
        this.districtTarget.classList.remove('hidden');
      } else {
        this.districtTarget.classList.add('hidden');
      }
    }
  }

  updateDisclaimer(countryName) {
    if (countryName === 'United States of America') {
      this.disclaimerTarget.classList.add('hidden');
    } else {
      this.disclaimerTarget.classList.remove('hidden');
    }
  }

  regionTargetInput() {
    return this.regionTarget.querySelector('select') || this.regionTarget.querySelector('input');
  }

  districtTargetInput() {
    if (this.hasDistrictTarget) {
      return this.districtTarget.querySelector('select');
    }
    return null;
  }
}
