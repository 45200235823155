import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { reveal: String };

  static targets = ['input', 'output'];

  change() {
    if (this.inputTarget.value === this.revealValue) {
      this.outputTarget.classList.remove('hidden');
    } else {
      this.outputTarget.classList.add('hidden');
    }
  }
}
