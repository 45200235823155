import { Controller } from 'stimulus';

function detailPositions(position) {
  return {
    top: ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
    'top-start': ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
    'top-mid': ['top-mid', 'top-start', 'top-end', 'bottom-mid', 'bottom-start', 'bottom-end'],
    'top-end': ['top-end', 'top-start', 'bottom-end', 'bottom-start'],
    bottom: ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
    'bottom-start': ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
    'bottom-end': ['bottom-end', 'bottom-start', 'top-end', 'top-start'],
    left: ['left-start', 'left-end', 'right-start', 'right-end'],
    'left-start': ['left-start', 'left-end', 'right-start', 'right-end'],
    'left-end': ['left-end', 'left-start', 'right-end', 'right-start'],
    right: ['right-start', 'right-end', 'left-start', 'left-end'],
    'right-start': ['right-start', 'right-end', 'left-start', 'left-end'],
    'right-end': ['right-end', 'right-start', 'left-end', 'left-start'],
  }[position];
}

function spacerToPixels(spacer) {
  const mapping = {
    1: 2,
    2: 4,
    3: 8,
    4: 16,
    5: 24,
    6: 32,
    7: 48,
    8: 64,
    9: 96,
    10: 128,
    11: 192,
  };
  return mapping[spacer];
}

function isOverflow(offset) {
  return offset.top < window.scrollY || offset.bottom > window.innerHeight + window.scrollY
           || offset.left < window.scrollX || offset.right > window.innerWidth + window.scrollX;
}

export default class extends Controller {
  static targets = ['tip', 'content'];

  static values = {
    position: { type: String, default: 'top' },
    gap: Number,
  };

  offset = null;

  show() {
    this.offset = this.compute(this.positionValue);
    this.tipTarget.style.top = `${this.offset.top}px`;
    this.tipTarget.style.left = `${this.offset.left}px`;
    this.toggleClass(true);
    this.dispatch('shown', { detail: { content: this.contentTarget, tip: this.tipTarget } });
  }

  hide() {
    this.toggleClass(false);
    this.dispatch('hidden', { detail: { content: this.contentTarget, tip: this.tipTarget } });
  }

  toggleClass(visible) {
    const { position } = this.offset;
    const kind = this.offset.position.split('-')[0];
    if (visible) {
      this.element.classList.add('st-tooltip--visible');
      this.tipTarget.classList.add(`st-tooltip__tip--${kind}`, `st-tooltip__tip--${position}`);
    } else {
      this.element.classList.remove('st-tooltip--visible');
      this.tipTarget.classList.remove(`st-tooltip__tip--${kind}`, `st-tooltip__tip--${position}`);
    }
  }

  compute(position) {
    const positions = detailPositions(position);
    for (let i = 0; i < positions.length; i += 1) {
      const offset = this.computeOffset(positions[i]);
      if (!isOverflow(offset)) {
        return offset;
      }
    }
    return this.computeOffset(positions[0]);
  }

  computeOffset(position) {
    const content = this.contentTarget;
    const tip = this.tipTarget;
    const gap = spacerToPixels(this.gapValue);
    const offset = { position };

    switch (position) {
      case 'top-start':
        offset.top = content.offsetTop - tip.offsetHeight - gap;
        offset.left = content.offsetLeft;
        break;
      case 'top-mid':
        offset.top = content.offsetTop - tip.offsetHeight - gap;
        offset.left = content.offsetLeft + (content.offsetWidth - tip.offsetWidth) / 2;
        break;
      case 'top-end':
        offset.top = content.offsetTop - tip.offsetHeight - gap;
        offset.left = content.offsetLeft + content.offsetWidth - tip.offsetWidth;
        break;
      case 'bottom-start':
        offset.top = content.offsetTop + content.offsetHeight + gap;
        offset.left = content.offsetLeft;
        break;
      case 'bottom-mid':
        offset.top = content.offsetTop + content.offsetHeight + gap;
        offset.left = content.offsetLeft + (content.offsetWidth - tip.offsetWidth) / 2;
        break;
      case 'bottom-end':
        offset.top = content.offsetTop + content.offsetHeight + gap;
        offset.left = content.offsetLeft + content.offsetWidth - tip.offsetWidth;
        break;
      case 'left-start':
        offset.top = content.offsetTop;
        offset.left = content.offsetLeft - tip.offsetWidth - gap;
        break;
      case 'left-end':
        offset.top = content.offsetTop + content.offsetHeight - tip.offsetHeight;
        offset.left = content.offsetLeft - tip.offsetWidth - gap;
        break;
      case 'right-start':
        offset.top = content.offsetTop;
        offset.left = content.offsetLeft + content.offsetWidth + gap;
        break;
      case 'right-end':
        offset.top = content.offsetTop + content.offsetHeight - tip.offsetHeight;
        offset.left = content.offsetLeft + content.offsetWidth + gap;
        break;
      default:
        // Top-mid
        offset.top = content.offsetTop - tip.offsetHeight - gap;
        offset.left = content.offsetLeft + (content.offsetWidth - tip.offsetWidth) / 2;
        break;
    }
    offset.bottom = offset.top + this.tipTarget.offsetHeight;
    offset.right = offset.left + this.tipTarget.offsetWidth;
    return offset;
  }
}
