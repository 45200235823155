import { Controller } from 'stimulus';
import {
  longdate, addPeriodToDate, singularDuration, DURATION_IN_MONTHS,
} from '../src/utils/datetime';
import { formatMoneyDecimal, parseMoneyInput } from '../src/utils/currency';

const DISPLAY = {
  PREVIEW: 'preview',
  ERROR: 'error',
  LINK: 'link',
};

const LINK_DISPLAY = {
  NO_WORKFLOW: 'No billing workflow included.',
  NO_STRIPE: 'Your fees are Stripe compatible.',
  LINK: 'A payment link will be sent after signing.',
};
export default class extends Controller {
  static targets = ['preview', 'error', 'errorMessage', 'period', 'periodInput', 'periodAmountInput', 'periodUnitInput', 'includeSimple',
    'simple', 'simpleInput', 'includeTable', 'metered', 'meteredItemsAndTaxes', 'meteredItems', 'taxes', 'taxesInput', 'startDateType', 'startDateInput',
    'heading', 'effectiveDateType', 'effectiveDateInput', 'startDate', 'endDate', 'renewalType', 'price', 'noPrice', 'billingType',
    'includeBilling', 'tablePeriodInput', 'nameInput', 'name', 'total', 'manageStripe', 'manageWorkflow', 'invoicePeriod', 'includeFreeTrial',
    'freeTrial', 'freeTrialInput', 'freeTrialDays', 'compatibleFeesMessage', 'billDeliveryOptionsSection', 'billDeliveryFutureDatedSection'];

  connect() {
    this.errorMessage = '';
    this.updatePreview();
  }

  nameInputTargetConnected() {
    this.updatePreview();
  }

  enableBillingWorkflow() {
    if (!this.includeBillingTarget.checked) {
      this.includeBillingTarget.click();
    }
  }

  updatePreview() {
    if (this.targetsOffScreen()) return;
    try {
      if (this.showLink()) {
        this.setLink();
        this.handlePreviewDisplay(DISPLAY.LINK);
      } else if (this.showError()) {
        this.handlePreviewDisplay(DISPLAY.ERROR);
      } else {
        this.updateTotalPerPeriod();
        this.updateMeteredItemsAndTaxes();
        this.updateFreeTrial();
        this.updateName();
        this.showBillingOptions();
        this.updateStartDate();
        this.updateEndDate();
        this.handlePreviewDisplay(DISPLAY.PREVIEW);
      }
    } catch (error) {
      console.error(`Cannot generate Stripe billing preview: ${error?.message}`);
      this.handlePreviewDisplay(DISPLAY.ERROR);
    }
  }

  showError() {
    return this.hasInvalidFees() || this.hasInvalidDuration() || this.hasInvalidAmount();
  }

  showLink() {
    const billingType = this.billingTypeTargets?.find((type) => type.checked)?.value;
    const billsWithStripe = this.includeBillingTarget.checked && billingType === 'stripe';
    if (billsWithStripe) {
      return !this.hasNameInputTarget;
    }
    return true;
  }

  hasConnectedStripeAccount() {
    return this.hasNameInputTarget;
  }

  hasBillingWorkflow() {
    return this.includeBillingTarget.checked;
  }

  setLink() {
    if (!this.hasConnectedStripeAccount()) {
      this.handleLinkDisplay(LINK_DISPLAY.NO_STRIPE);
    } else if (!this.hasBillingWorkflow()) {
      this.handleLinkDisplay(LINK_DISPLAY.NO_WORKFLOW);
    } else {
      this.handleLinkDisplay(LINK_DISPLAY.LINK);
    }
  }

  handleLinkDisplay(linkDisplay) {
    switch (linkDisplay) { // eslint-disable-line default-case
      case LINK_DISPLAY.NO_WORKFLOW:
        this.manageWorkflowTarget.children[0].innerHTML = LINK_DISPLAY.NO_WORKFLOW;
        this.manageStripeTarget.classList.add('hidden');
        this.manageWorkflowTarget.classList.remove('hidden');
        break;
      case LINK_DISPLAY.NO_STRIPE:
        this.handleCompatibleFeesDisplay();
        this.manageStripeTarget.classList.remove('hidden');
        this.manageWorkflowTarget.classList.add('hidden');

        break;
      case LINK_DISPLAY.LINK:
        this.manageWorkflowTarget.children[0].innerHTML = LINK_DISPLAY.LINK;
        this.manageStripeTarget.classList.add('hidden');
        this.manageWorkflowTarget.classList.remove('hidden');
        break;
    }
  }

  handleCompatibleFeesDisplay() {
    if (this.hasInvalidFees() || this.hasInvalidFeeValues()) {
      this.compatibleFeesMessageTarget.classList.add('hidden');
    } else {
      this.compatibleFeesMessageTarget.classList.remove('hidden');
    }
  }

  handlePreviewDisplay(previewDisplay) {
    switch (previewDisplay) { // eslint-disable-line default-case
      case DISPLAY.PREVIEW:
        this.errorMessage = '';
        this.errorTarget.classList.add('hidden');
        this.previewTarget.classList.remove('hidden');
        this.headingTarget.classList.remove('hidden');
        this.manageStripeTarget.classList.add('hidden');
        this.manageWorkflowTarget.classList.add('hidden');
        break;
      case DISPLAY.ERROR:
        this.errorMessageTarget.textContent = this.errorMessage;
        this.errorTarget.classList.remove('hidden');
        this.previewTarget.classList.add('hidden');
        this.headingTarget.classList.remove('hidden');
        this.manageStripeTarget.classList.add('hidden');
        this.manageWorkflowTarget.classList.add('hidden');
        break;
      case DISPLAY.LINK:
        this.errorMessage = '';
        this.errorTarget.classList.add('hidden');
        this.previewTarget.classList.add('hidden');
        this.headingTarget.classList.add('hidden');
        break;
    }
  }

  updateTotalPerPeriod() {
    if (this.hasValidFees() && this.hasValidFeeValues()) {
      const feeAmount = this.amountForFeeType();
      const subscriptionPeriod = this.subscriptionPeriodBySelection();
      const invoicePeriod = this.invoicePeriodTarget.value;
      this.calculatePrice(feeAmount, invoicePeriod, subscriptionPeriod);
    } else {
      this.priceTarget.classList.add('hidden');
      this.noPriceTarget.classList.remove('hidden');
    }
  }

  calculatePrice(amount, invoicePeriod, subscriptionPeriod) {
    const INVOICE_PERIOD_DISPLAY_DURATION = {
      annually: 'year',
      semiannually: '6 months',
      quarterly: '3 months',
      monthly: 'month',
    };
    const subscriptionPeriodMonths = DURATION_IN_MONTHS[subscriptionPeriod.unit] * subscriptionPeriod.multiple;
    const invoicePeriodMonths = DURATION_IN_MONTHS[invoicePeriod];
    const multiple = subscriptionPeriodMonths / invoicePeriodMonths;
    this.simpleTarget.innerHTML = formatMoneyDecimal(amount / multiple);
    this.periodTarget.innerHTML = INVOICE_PERIOD_DISPLAY_DURATION[invoicePeriod];
    this.priceTarget.classList.remove('hidden');
    this.noPriceTarget.classList.add('hidden');
  }

  updateMeteredItemsAndTaxes() {
    this.taxesTarget.classList.add('hidden');
    this.meteredItemsTarget.classList.add('hidden');
    this.meteredItemsAndTaxesTarget.classList.add('hidden');

    if (this.hasMeteredFees() && !this.taxesInputTarget.checked) {
      this.meteredItemsAndTaxesTarget.classList.remove('hidden');
      return;
    }

    if (this.hasMeteredFees()) {
      this.meteredItemsTarget.classList.remove('hidden');
    }

    if (!this.taxesInputTarget.checked) {
      this.taxesTarget.classList.remove('hidden');
    }
  }

  hasMeteredFees() {
    // We can't look at just this.meteredTargets here because the elements stay in the DOM
    // even after pressing the `x` to remove the row - we just visually hide them
    // Instead, we look for any rows that are visible to the user.
    const displayedFees = this.meteredTargets.filter((r) => r.style.display !== 'none');
    return displayedFees.length !== 0;
  }

  updateFreeTrial() {
    const days = this.freeTrialInputTarget.value;
    if (this.includeFreeTrialTarget.checked && days) {
      this.freeTrialDaysTarget.innerHTML = days;
      this.freeTrialTarget.classList.remove('hidden');
    } else {
      this.freeTrialTarget.classList.add('hidden');
    }
  }

  updateName() {
    if (this.nameInputTargets[0].innerHTML !== 'Create new product') {
      this.nameTarget.innerHTML = this.nameInputTargets[0].innerHTML;
    } else if (this.nameInputTargets[1].value) {
      this.nameTarget.innerHTML = this.nameInputTargets[1].value;
    }
  }

  updateStartDate() {
    const useEffectiveDate = this.startDateTypeTargets.find((type) => type.checked).value === 'effective_date';

    if (useEffectiveDate) {
      const effectiveDateType = this.effectiveDateTypeTargets.find((x) => x.checked)?.value;
      if (effectiveDateType === 'signature') {
        this.startDateTarget.innerHTML = 'Date of last signature';
      } else if (effectiveDateType === 'date') {
        const input = this.effectiveDateInputTarget.value;
        if (input) {
          const date = new Date(input);
          if (date > new Date()) {
            this.hideBillingOptions();
          }
          this.startDateTarget.innerHTML = longdate(input);
        } else {
          this.startDateTarget.innerHTML = '';
        }
      } else {
        this.startDateTarget.innerHTML = 'Effective date';
      }
    } else {
      const input = this.startDateInputTarget.value;
      const date = new Date(input);
      if (date > new Date()) {
        this.hideBillingOptions();
      }
      this.startDateTarget.innerHTML = input ? longdate(input) : '';
    }
  }

  effectiveStartDate() {
    const useEffectiveDate = this.startDateTypeTargets.find((type) => type.checked).value === 'effective_date';

    if (useEffectiveDate) {
      const effectiveDateType = this.effectiveDateTypeTargets.find((x) => x.checked)?.value;
      if (effectiveDateType === 'signature') {
        return null;
      } if (effectiveDateType === 'date') {
        const input = this.effectiveDateInputTarget.value;
        if (input) {
          return new Date(input);
        }
        return null;
      }
      return null;
    }
    const input = this.startDateInputTarget.value;
    return new Date(input);
  }

  showBillingOptions() {
    if (this.hasBillDeliveryOptionsSectionTarget) {
      this.billDeliveryOptionsSectionTarget.classList.remove('hidden');
    }
    if (this.hasBillDeliveryFutureDatedSectionTarget) {
      this.billDeliveryFutureDatedSectionTarget.classList.add('hidden');
    }
  }

  hideBillingOptions() {
    if (this.hasBillDeliveryOptionsSectionTarget) {
      this.billDeliveryOptionsSectionTarget.classList.add('hidden');
    }
    if (this.hasBillDeliveryFutureDatedSectionTarget) {
      this.billDeliveryFutureDatedSectionTarget.classList.remove('hidden');
    }
  }

  updateEndDate() {
    const autoRenews = this.renewalTypeTargets.find((type) => type.checked).value === 'days';
    if (autoRenews) {
      this.endDateTarget.innerHTML = 'Auto-renews';
    } else {
      this.calculateEndDate();
    }
  }

  hasInvalidDuration() {
    return !this.usesValidSubscriptionPeriod()
    || !this.usesValidInvoicePeriod()
    || !this.validInvoiceSubscriptionPeriodRelationship();
  }

  hasInvalidFees() {
    const hasFees = this.includeSimpleTarget.checked || this.includeTableTarget.checked;
    if (!hasFees) {
      this.errorMessage = "We can't determine the fees to charge in Stripe from only the text and/or attachment fields alone. To use Stripe billing, either select the table or fees per period option.";
      return true;
    }
    return false;
  }

  hasValidFees() {
    return !this.hasInvalidFees();
  }

  hasInvalidFeeValues() {
    return !this.hasValidFeeValues();
  }

  hasValidFeeValues() {
    if (this.includeSimpleTarget.checked) {
      if (this.simpleInputTarget.value.length === 0) {
        return false;
      }
      return true;
    } if (!this.hasTotalTarget || this.totalTarget.innerHTML === '0.00') {
      return false;
    }
    return true;
  }

  hasInvalidAmount() {
    const amount = this.amountForFeeType();
    if (amount <= 0 && !this.hasMeteredFees()) {
      this.errorMessage = 'Stripe only supports subscriptions greater than $0.';
      return true;
    } if (amount >= 1000000) {
      this.errorMessage = "Stripe doesn't support subscriptions larger than $1,000,000.";
      return true;
    }
    return false;
  }

  usesValidSubscriptionPeriod() {
    const periodValue = this.subscriptionPeriodForFeeType();
    if (periodValue === 'Subscription Period') {
      if (this.periodUnitInputTarget.value === 'day(s)' && this.periodUnitInputTarget.value === 'week(s)') {
        this.errorMessage = 'Our Stripe integration currently only support monthly, yearly or Subscription Period billing.';
        return false;
      }
    }
    if (periodValue === 'Custom unit' || periodValue === 'User') {
      this.errorMessage = 'Our Stripe integration currently only supports monthly, yearly or Subscription Period billing.';
      return false;
    }
    return true;
  }

  usesValidInvoicePeriod() {
    if (this.invoicePeriodTarget.value === 'Custom unit') {
      this.errorMessage = "Our Stripe integration doesn't currently support an Invoice Period with Custom unit.";
      return false;
    }
    return true;
  }

  validInvoiceSubscriptionPeriodRelationship() {
    const subscriptionPeriod = this.subscriptionPeriodWithMultiple();
    const invoicePeriod = this.invoicePeriodTarget.value;
    const subscriptionPeriodMonths = DURATION_IN_MONTHS[subscriptionPeriod.unit] * subscriptionPeriod.multiple;
    const invoicePeriodMonths = DURATION_IN_MONTHS[invoicePeriod];
    if (invoicePeriodMonths > subscriptionPeriodMonths) {
      this.errorMessage = 'Stripe needs the invoice frequency to be shorter than the subscription length.';
      return false;
    }
    return true;
  }

  amountForFeeType() {
    const amount = this.includeTableTarget.checked
      ? this.totalTarget.innerHTML
      : this.simpleInputTarget.value;
    return parseMoneyInput(amount);
  }

  subscriptionPeriodForFeeType() {
    return this.includeTableTarget.checked
      ? this.tablePeriodInputTarget.value
      : this.periodInputTarget.value;
  }

  subscriptionPeriodBySelection() {
    const period = this.subscriptionPeriodForFeeType();
    if (period === 'Subscription Period') {
      return this.subscriptionPeriodWithMultiple();
    }
    return { unit: period, multiple: 1 };
  }

  subscriptionPeriodWithMultiple() {
    const unit = singularDuration(this.periodUnitInputTarget.value);
    const multiple = Number(this.periodAmountInputTarget.value);
    return { unit, multiple };
  }

  calculateEndDate() {
    const useEffectiveDateStart = this.startDateTypeTargets.find((type) => type.checked).value === 'effective_date';
    if (useEffectiveDateStart) {
      this.endDateTarget.innerHTML = 'Based on Effective Date';
    } else {
      const input = this.startDateInputTarget.value;
      this.endDateTarget.innerHTML = input ? longdate(this.dateOffset(input)) : '';
    }
  }

  targetsOffScreen() {
    // prevents console error when we display alternate/no content
    return !this.hasPreviewTarget || !this.hasErrorTarget;
  }

  dateOffset(date) {
    // eslint-disable-next-line max-len
    return addPeriodToDate(date, this.periodUnitInputTarget.value, this.periodAmountInputTarget.value);
  }
}
