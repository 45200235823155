import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['governingLaw', 'chosenCourts', 'districtOrCounty', 'address', 'selector'];

  static outlets = ['courts-and-jurisdiction'];

  static values = {
    url: String,
  };

  async update() {
    this.subsidiaries = await this.getSelectOptions();
    const selected = await this.findSubsidiary();
    this.subsidiary = selected;
    this.updateAddress(selected);

    if (selected.update_terms && this.hasTerms()) {
      this.updateTerms(selected);
    }
  }

  async updateTerms(selected) {
    if (selected.courts) {
      this.governingLawTarget.value = selected.courts;
      $('[data-subsidiary-target="governingLaw"]').trigger('change.select2');
    }
    if (selected.jurisdiction) {
      this.chosenCourtsTarget.value = selected.jurisdiction;
      $('[data-subsidiary-target="chosenCourts"]').trigger('change.select2');
      if (this.hasCourtsAndJurisdictionOutlet) {
        this.courtsAndJurisdictionOutlet.updateState();
      }
    }
    if (selected.district_or_county || selected.jurisdiction) {
      await this.updateCounty(selected?.district_or_county);
      $('[data-subsidiary-target="districtOrCounty"]').trigger('change.select2');
    }
  }

  async updateCounty(county = null) {
    let newCounty = county;
    if (!county) {
      const subsidiary = await this.subsidiary || this.findSubsidiary();
      newCounty = subsidiary?.district_or_county || null;
    }
    this.districtOrCountyTarget.selected = newCounty;
  }

  async findSubsidiary() {
    const selectedId = this.selectorTarget.value;
    if (!this.subsidiaries) {
      this.subsidiaries = await this.getSelectOptions();
    }
    return this.subsidiaries.find((subsidiary) => subsidiary.id === selectedId);
  }

  hasTerms() {
    return Boolean(this.hasGoverningLawTarget && this.hasChosenCourtsTarget && this.hasDistrictOrCountyTarget);
  }

  updateAddress(selected) {
    this.addressTarget.innerHTML = `${selected.street_address}, ${selected.city}, ${selected.state}, ${selected.zipcode}, ${selected.country}`;
  }

  async getSelectOptions() {
    if (this.subsidiaries) {
      return this.subsidiaries;
    } if (this.hasUrlValue) {
      const response = await fetch(this.urlValue, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      return response.json();
    }
    return null;
  }
}
